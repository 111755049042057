import Colors from './Colors'
import Margin from './Margin'
import Padding from './Padding'
import Typography from './Typography'

export default {
  Colors,
  Margin,
  Padding,
  Typography,
}
